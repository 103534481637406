<template>
</template>

<script>
    import store from '../store';
    import { mapState } from 'vuex';

    export default {
        name: 'Home',
        computed: {
            ...mapState('auth', ['user']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            if(this.user.id) {
                this.$router.push({ name: 'orders-list' })
            }
        },
    }
</script>
